// Services
import {getTranslation} from "@/services/app/translation";

const getInformation = (data: string): string => {
  if (data && data.length) {
    return data;
  }

  const translation = getTranslation([
    "undefined",
  ]);

  return translation.undefined;
};

const getCurrencyFormatHRK = (data: string): string => {
  if (data && data.length) {
    return Number(data).toLocaleString('hr-HR', {
      style: 'currency',
      currency: 'HRK',
    });
  }

  return Number(0).toLocaleString('hr-HR', {
    style: 'currency',
    currency: 'HRK',
  });
};

const getCurrencyFormatEUR = (data: string): string => {
  if (data && data.length) {
    return Number(data).toLocaleString('hr-HR', {
      style: 'currency',
      currency: 'EUR',
    });
  }

  return Number(0).toLocaleString('hr-HR', {
    style: 'currency',
    currency: 'EUR',
  });
};

const getInvoiceStatus = (data: string): string => {
  const translation = getTranslation([
    "opened",
    "closed",
  ]);

  if (data === "0") {
    return translation.opened;
  }

  return translation.closed;
};

const getInvoiceStatusShort = (data: string): string => {
  const translation = getTranslation([
    "openedShort",
    "closedShort",
  ]);

  if (data === "0") {
    return translation.openedShort;
  }

  return translation.closedShort;
};

const getInvoiceStatusClass = (data: string): string => {
  if (data === "0") {
    return "opened";
  }

  return "closed";
};

const getInvoiceNumberInYear = (number: string, year: string): string => {
  return `${number}/${year}`;
};

const getInvoiceItemsStatus = (data: string): boolean => {
  return data === "1";
};

export {
  getInformation,
  getCurrencyFormatHRK,
  getCurrencyFormatEUR,
  getInvoiceStatus,
  getInvoiceStatusShort,
  getInvoiceStatusClass,
  getInvoiceNumberInYear,
  getInvoiceItemsStatus,
};
